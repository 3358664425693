import React from "react"
import Layout from "../../../components/sermonLayoutElMirage"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/32cNRl6gw4s">
    <SEO title="The Disciplined Life - Hebrews" />
  </Layout>
)

export default SermonPost
